import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Header from "../component/header/Header";
import FooterTwoFunnel from "../component/footer/FooterTwoFunnel";
import axios from "axios";
import Selectrix from "react-selectrix";
import Modal from "react-bootstrap/Modal";
import { FaGraduationCap, FaWhatsapp, FaPhone, FaRunning, FaEye, FaCreditCard, FaPaypal } from "react-icons/fa";
import { MdWork, MdEmail, MdPayments, MdModeNight, MdOutlineZoomOutMap } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";
import {
  AiOutlineDown,
  AiOutlineRight,
  AiFillPlayCircle,
  AiFillPauseCircle,
} from "react-icons/ai";
import "react-toastify/dist/ReactToastify.css";
import ReactCountryFlag from "react-country-flag";
import ReactPlayer from "react-player";
import FlipCountdown from "@rumess/react-flip-countdown";
import { GoRocket } from "react-icons/go";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import { CiUser } from "react-icons/ci";
import { IoIosCloseCircleOutline } from "react-icons/io";
import ProgressBar from 'react-bootstrap/ProgressBar';
import Typical from 'react-typical';
import { FiCheck, FiClock, FiUser, FiMonitor, FiRadio, FiDownload, FiVideo, FiCheckCircle, FiPackage} from "react-icons/fi";
import { BsCircle, BsArrowLeftCircle, BsSun, BsFillPlayCircleFill, BsCloudSunFill } from "react-icons/bs";
import cx from "classnames";
import NonPassiveTouchTarget from "../home/NonPassiveTouchTarget";
import TouchCarousel, { clamp } from "react-touch-carousel";
import touchWithMouseHOC from "react-touch-carousel/lib/touchWithMouseHOC";
import data2 from "../home/data2";

const KeyCountry =  [
    {key: "PE", label: "Perú"},
    {key: "BO", label: "Bolivia"},
    {key: "MX", label: "México"},
    {key: "CO", label: "Colombia"},
    {key: "EC", label: "Ecuador"},
    {key: "PR", label: "Puerto Rico"},
    {key: "SV", label: "El Salvador"},
    {key: "CL", label: "Chile"},
    {key: "CR", label: "Costa Rica"},
    {key: "HN", label: "Honduras"},
    {key: "ES", label: "España"},
    {key: "UY", label: "Uruguay"},
    {key: "GT", label: "Guatemala"},
    {key: "PA", label: "Panamá"},
    {key: "NI", label: "Nicaragua"},
    {key: "PY", label: "Paraguay"},
    {key: "DO", label: "República Dominicana"},
    {key: "BR", label: "Brasil"},
    {key: "US", label: "Estados Unidos"},
    {key: "CA", label: "Canada"},
]



const Container2 = touchWithMouseHOC(CarouselContainer2);

const cardSize = 300;
const cardSize2 = 280;
const cardPadCount = 2;
const carouselWidth = clamp(window.innerWidth, 0, 960);


function CarouselContainer2(props) {
  const {
    cursor,
    carouselState: { active, dragging },
    ...rest
  } = props;
  let current = -Math.round(cursor) % data2.length;
  while (current < 0) {
    current += data2.length;
  }
  // Put current card at center
  const translateX =
    (cursor - cardPadCount) * 300 + (carouselWidth - 300) / 2;
  return (
    <NonPassiveTouchTarget
      className={cx("carousel-container", {
        "is-active": active,
        "is-dragging": dragging,
      })}
      style={{height: "450px"}}
    >
      <NonPassiveTouchTarget
        className="carousel-track"
        style={{ transform: `translate3d(${translateX}px, 0, 0)` }}
        {...rest}
      />

      <div className="carousel-pagination-wrapper">
        <ol className="carousel-pagination">
          {data2.map((_, index) => (
            <li key={index} className={current === index ? "current" : ""} />
          ))}
        </ol>
      </div>
    </NonPassiveTouchTarget>
  );
}



// Componente funcional memoizado
const TypingAnimation = () => {
  return (
      <Typical
          steps={['¡Imagina lo que Puedes Lograr!', 500, "Con el Potencial del Perfil Full Stack Web.", 500]}
          wrapper="h3"
          loop={100}
          />
  );
};

const MemoizedTypingAnimation = React.memo(TypingAnimation, (props,prevProp)=> true);




class VslDefault extends Component {
  constructor() {
    super();

    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    this.state = {
      pricingAPI: {},
      bootcampSlug: 'fullstack-descuento',
      bootcampSlugMensual: "fullstack-descuento",
      Temario: "",
      Programa: "",
      isOpen: false,
      countryCode: "PE",
      Email: "",
      Phone: "",
      Name: "",
      isOpenTemario: false,
      isPdfPrice: false,
      langUS: false,
      isPlaying: false,
      isOpenPlanPay: false,
      price_mensual_regular: "US$ 159 mensual",
      price_completo_regular: "",
      price_completo: "",
      price_mensual: "US$ 125",
      titlePricePre: "Antes del viernes 17 de noviembre",
      demoTitle: "",
      demoUrl: "",
      demoPreview: "",
      fechaLimite: "",
      DescuentoBody: "",
      visitasNumber: this.getRandomVisitasNumber(),
      videoRef: React.createRef(),
      isOpenLead: false,
      loadingEnroll: false,
      demo_alt: false,
      body_intro: false,
      select_mes: "",
      DataFechas: [],
      select_turno: "",
      isOpenResume: false,
      Turnos: [
        {key: "noche", label: "Noche"},
        {key: "tarde", label: "Tarde"},
    ],
      durationVideo: 0,
      durationPercen: 0,
      registerRequerid: false,
      videoInit: false,
      mount: "",
      isOpen2: false,
      title_partes: 'o en 5 pagos de',
      planPagoUSD: "",
      planPagoPE: "",
      isOpenPlanPay: false,
      DescuentoBody: "",
      price_mount: "",
      selectFecha: false,
      DescuentoBodyUSD: "",
      DescuentoBodyPEN: "",
      isOpenReunion: false,
      cuotaDolar: '',
      cuotaSoles: '',
      isOpenModalInfo: false,
      isModalWS: false,
      showMore: false,
      showTestimonial: false,
      showButton: false,
      sendEventContent: false
    };
    this.GetLinkWS = this.GetLinkWS.bind(this);
    this.openModalEmail = this.openModalEmail.bind(this);
    this.onRenderOption = this.onRenderOption.bind(this);
    this.DownloadPdf = this.DownloadPdf.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.DynamicCountry = this.DynamicCountry.bind(this);
    this.openModal2 = this.openModal2.bind(this);


  }

  openModal2 () {
    this.setState({isOpen2: !this.state.isOpen2});
  }






  onSubmit(e) {
    // e.preventDefault();
    // if (this.state.Email.trim() === "") {
    //   toast.warning("El campo Email es requerido.");
    //   return;
    // }

    if (this.state.Phone.trim() === "" || this.state.Phone.length <= 4 ) {
      toast.warning("¡Ups! El campo de WhatsApp es necesario para continuar.");
      return;
    }


    this.setState({ loadingEnroll: true, sendDataForm: true });

    // localStorage.setItem("email", this.state.Email);
    localStorage.setItem('name', this.state.Name || "");
    // localStorage.setItem('phone', this.state.Phone);

    setTimeout(() => {
      const link = this.GetLinkWS();
      this.setState({loadingEnroll: false, isModalWS: true });
      window.open(link, '_blank').focus();
    }, 2000);

    const source = window.location.search.split('source=')[1] || '';


    window.fbq('track', 'Contact');
    
    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: "Web full stack",
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        phone: this.state.Phone,
        Token: "c3b4b89c",
        landing: 'funnel-vsl',
        source: source,
      })
      .then((res) => {
        console.log("");
        // const link = this.GetLinkWS();
        // this.setState({loadingEnroll: false });
        // window.open(link, '_blank').focus();
      });
  }


  getRandomVisitasNumber() {
    const rango = Array.from({ length: 7 }, (_, index) => index + 10);
    return rango[Math.floor(Math.random() * rango.length)];
  }

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };


  

  async DynamicCountry (country='' ){

    window.urlWasi = this.GetLinkWS();

    const newCountry = country || this.state.countryCode;

    if (country){
        this.setState({countryCode: country});

      const response = await axios.get(
        "https://hiuaik2gocmwdeh2ub6lqz3hxq0ycvbe.lambda-url.us-east-1.on.aws/"
      );

      const pricingAPI = response.data;
      let urlVid;

      const imagePoster = pricingAPI[this.state.bootcampSlug]["imagePoster"];

      if (window.type_device){
        urlVid = pricingAPI[this.state.bootcampSlug]["vls_default"];
      } else {
        urlVid = pricingAPI[this.state.bootcampSlug]["vls_default"];
      }

      this.setState({
        demoUrl: urlVid,
        select_mes:  pricingAPI[this.state.bootcampSlug]['fechas'][0]['mes'],
        isPricePais: false,
        utc_offset: '-0500',
        utc_dif: 0,
        timestamp24h: pricingAPI[this.state.bootcampSlug]['timestamp24h'],
        semibeca_img: pricingAPI[this.state.bootcampSlug]['semibeca_img'],
        DataFechas: pricingAPI[this.state.bootcampSlug]['fechas'],
        mesesInicio: pricingAPI[this.state.bootcampSlug]['mesesInicio'],
        Turnos: pricingAPI[this.state.bootcampSlug]['turnos'],
        fechaLimite: pricingAPI[this.state.bootcampSlug]['fechaLimite'],
        isPriceEspecial: pricingAPI[this.state.bootcampSlug]['isPriceEspecial'],
        PriceEspecialBody: pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        PriceEspecialPreBody: pricingAPI[this.state.bootcampSlug]['PriceEspecialBody'],
        timePrueba: pricingAPI[this.state.bootcampSlug]['time_prueba'],
        FooterColor: pricingAPI[this.state.bootcampSlug]['FooterColor'],
        price_completo: pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base'], //495
        price_completo_regular: pricingAPI[this.state.bootcampSlug]['price']['usd']['price_view_base_regular'],
        price_mensual: pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_final'],
        price_mensual_regular: pricingAPI[this.state.bootcampSlugMensual]['price']['usd']['price_view_base_regular'],
        titlePrice: pricingAPI[this.state.bootcampSlug]['titlePrice'],
        titlePricePre: pricingAPI[this.state.bootcampSlug]['titlePrice'],
        price_payment: "1",
        salario_fullstack_jr: "US$ 14,000 ANUAL",
        salario_fullstack_md: "US$ 24,000 ANUAL",
        salario_fullstack_sr: "+US$ 40,000 ANUAL",
        planPagoUSD: pricingAPI[this.state.bootcampSlug]['planPagoUSD'],
        planPagoPE: pricingAPI[this.state.bootcampSlug]['planPagoPE'],
        DescuentoBody: pricingAPI[this.state.bootcampSlug]['DescuentoBody'],
        price_mount: pricingAPI[this.state.bootcampSlug]['price']['usd']['mount'],
        DescuentoBodyUSD: pricingAPI[this.state.bootcampSlug]['DescuentoBodyUSD'],
        DescuentoBodyPEN: pricingAPI[this.state.bootcampSlug]['DescuentoBodyPEN'],
        cuotaDolar: pricingAPI[this.state.bootcampSlug]['cuotaDolar'],
        cuotaSoles: pricingAPI[this.state.bootcampSlug]['cuotaSoles'],
    })

    try {
        if (pricingAPI[this.state.bootcampSlug].price[country]){
            this.setState({
                isPricePais: true,
                price_completo: pricingAPI[this.state.bootcampSlug].price[country].price_view_base,
                price_completo_regular: pricingAPI[this.state.bootcampSlug].price[country].price_view_base_regular,
                price_mensual: pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_final'],
                price_mensual_regular: pricingAPI[this.state.bootcampSlugMensual]['price'][country]['price_view_base_regular']
            });
        }
    } catch (error) {
        console.log('Log',error);
    }


    if (newCountry === 'PE') {
        this.setState({
            utc_offset: '-0500',
            salario_fullstack_jr: "S/ 45,000 ANUAL",
            salario_fullstack_md: "S/ 79,00 ANUAL",
            salario_fullstack_sr: "+ S/ 135,000 ANUAL"
        });
    } else if (newCountry === 'MX') {
        this.setState({
            utc_dif: -1,
            utc_offset: '-0600',
            salario_fullstack_jr: "$300,000 MXN ANUAL",
            salario_fullstack_md: "$450,000 MXN ANUAL",
            salario_fullstack_sr: "+ $800,000 MXN ANUAL"
            });
    } else if (newCountry === 'CR'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'CL'){
        this.setState({utc_dif: -1, utc_offset: '-0400'});
    } else if (newCountry === 'BO'){
        this.setState({
            utc_dif: -1,
            utc_offset: '-0400',
            });
    } else if (newCountry === 'CU'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'SV'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'GT'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'HN'){
        this.setState({utc_dif: -1, utc_offset: '-0600'});
    } else if (newCountry === 'NI'){
        this.setState({utc_dif: -1});
    } else if (newCountry === 'PY'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'PR'){
        this.setState({utc_dif: +1, utc_offset: '-0300'});
    } else if (newCountry === 'DO'){
        this.setState({utc_dif: +1});
    } else if (newCountry === 'UY'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    } else if (newCountry === 'BR'){
        this.setState({
            utc_dif: +2,
            utc_offset: '-0300'
        });
    }


    this.sleep(250).then(r => {
      try {
        document.querySelector("#video-play > video").poster = imagePoster;

        document.querySelector("#video-play > video").muted = true;
        document.querySelector("#video-play > video").play();

      } catch (error) {
      }
    })

    // this.sleep(5000).then(r => {
    //   this.setState({
    //     showTestimonial: true
    //   });
    // })

    // this.sleep(13000).then(r => {
    //   this.setState({
    //     showMore: true
    //   });
    // })


    }
    

}


  renderPrice(price, prueba = false) {
    return (
      <>
        <div className="row" style={{ placeContent: "center" }}>
          <div className="col-12">
            <p
              style={{
                fontSize: "45px",
                color: prueba ? "black" : "white",
                fontWeight: "bold",
                paddingTop: "9px",
                paddingBottom: "9px",
              }}
            >
              {price}
              <span
                style={{
                  display: "inline-block",
                  fontSize: window.type_device ? "20px" : "25px",
                  marginLeft: "5px",
                }}
              >
                {" "}
              </span>
            </p>
          </div>
        </div>
      </>
    );
  }

  
    
  onRenderOption(option, complete) {
    return (
      <li>
        <ReactCountryFlag
          countryCode={option.key}
          svg
          style={{
            width: "2em",
            height: "2em",
            padding: "1px",
          }}
          title="país"
        />{" "}
        {complete ? option.label : option.key}
      </li>
    );
  }


  onRenderSelectionPais( selected, settings, deselect ){
    return 	(<span style={{ marginRight: 10, padding: 5 }}>
{ selected ?
        <span style={{"color": "#1f1f25"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2.3em',
                height: '2.3em',
                padding: '7px'
            }}
            title="país"
    />  {selected.countryNumber}
        </span>
        :
           <span style={{padding: 5}}>País</span>
    }
    
<i style={{ paddingLeft: 5, cursor: "pointer" }} onClick={ deselect } className="fa fa-window-close"></i>
</span>)
}



  onRenderSelection(selected, complete) {
    return (
      <span style={{ padding: 2 }}>
        {selected ? (
          <span style={{ color: "black" }}>
            <ReactCountryFlag
              countryCode={selected.key}
              svg
              style={{
                width: "2.3em",
                height: "2.3em",
                padding: "2px",
              }}
              title="país"
            />{" "}
            {complete ? selected.label : selected.key}
          </span>
        ) : (
          <span style={{ padding: 5 }}></span>
        )}
      </span>
    );
  }

  GetLinkWS() {
    if (window.type_device) {
      return `https://api.whatsapp.com/send?phone=51935489552&text=Hola,%20me%20gustaría%20saber%20más%20sobre%20el%20Bootcamp%20Full%20Stack%20online.`;
    } else {
      return `https://web.whatsapp.com/send?phone=51935489552&text=Hola,%20me%20gustaría%20saber%20más%20sobre%20el%20Bootcamp%20Full%20Stack%20online.`;
    }
  }

  openModalEmail() {
    this.setState({ Phone: "" });
    this.setState({ isOpenTemario: !this.state.isOpenTemario });
  }

  DownloadPdf(e) {
    e.preventDefault();

    if (this.state.Email === "") {
      toast.warning("El campo Email es requerido.");
      return;
    }

    localStorage.setItem("email", this.state.Email);

    toast.success(
      !this.state.langUS
        ? "Hola!, enviamos el programa por Email, gracias!"
        : "Hello! We sent the program by Email, thank you!"
    );

    this.openModalEmail();

    axios
      .post("https://cprij4toee.execute-api.us-east-1.amazonaws.com/dev", {
        lead: true,
        evento: true,
        bootcamp: this.state.slugBootcamp,
        email: this.state.Email,
        name: this.state.Name,
        country: this.state.countryCode,
        Token: "c3b4b89c",
      })
      .then((res) => {
        window.gtag_report_conversion();
        // window.location.href = `/inscripcion/fullstack/${this.state.countryCode}`;
        // toast.success(`Hola ${this.state.Name}!, tengamos una breve sesión informativa para completar tu inscripción`);
      });
  }


  onRenderOption ( option, complete ) {
    return (
        <li>
        <ReactCountryFlag
            countryCode={option.key}
            svg
            style={{
                width: '1.5em',
                height: '1.5em',
                padding: '1px'
            }}
            title="país"
        />  {option.key }
        </li>
            )
}

onRenderSelection( selected, complete ){
    return 	(<span style={{ padding: 2 }}>
    { selected ?
        <span style={{"color": "black"}}>
            <ReactCountryFlag
            countryCode={selected.key}
            svg
            style={{
                width: '2em',
                height: '2em',
                padding: '2px'
            }}
            title="país"
    />
        </span>
        :
           <span style={{padding: 5}}></span>
    }
</span>)
}

renderCard2(index, modIndex) {
  const item = data2[modIndex];
  return (
      <div
      key={index}
      className="carousel-card"
      >
      <div style={{width: "275px"}}>
         <div style={{"text-align":"center", "box-shadow": "4px 2px 5px 0px #8080805e", "border-radius": "30px", "padding": "23px", "background": "white"}}>
              <h4 style={{fontSize: "18px"}}>
              {item.title}
              </h4>
              <img alt={item.title} height="130px" src={item.background} />
          </div>
      </div>
      </div>
  );
  }

renderPais(complete=false){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} > Zona horaria </span>
            <Selectrix
        height={300}
        onRenderOption={(option) => this.onRenderOption(option, complete)}
        onRenderSelection={(option) => this.onRenderSelection(option, complete)}
        placeHolderInside={true}
        placeholder={"País"}
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.countryCode}
        options={KeyCountry}
        onChange={value => {
            this.DynamicCountry(value.key)}
        }
    />
        </>

    )
}







renderMes(){
  return (
      <>
      <span style={{
          fontSize: "17px",
          fontWeight: "bold",
          position: "absolute",
          left: "15px",
          top: "-25px",
          zIndex: "1",
      }} > {!this.state.langUS ? "Mes:": "Month:"} </span>
      <Selectrix
      height={250}
      placeHolderInside={true}
      placeholder={!this.state.langUS ? "Todos": "All" }
      customScrollbar={true}
      searchable={false}
      materialize={false}
      defaultValue={this.state.select_mes}
      options={this.state.mesesInicio.map((item) => ({key: item, label: item})) }
      onChange={value => this.setState({select_mes: value.key})}
  />
      </>

  )
}


renderTurno(){
    return (
        <>
        <span style={{
            fontSize: "17px",
            fontWeight: "bold",
            position: "absolute",
            left: "15px",
            top: "-25px",
            zIndex: "1",
        }} >{!this.state.langUS ? "Turno": "Shift"}:</span>
        <Selectrix
        height={250}
        placeHolderInside={true}
        placeholder={!this.state.langUS ? "Todos": "All" }
        customScrollbar={true}
        searchable={false}
        materialize={false}
        defaultValue={this.state.select_turno}
        options={this.state.Turnos}
        onChange={value => this.setState({select_turno: value.key})}
    />
        </>

    )
}

renderHorario(){
  if (this.state.utc_offset === "-0500") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  } else if (this.state.utc_offset === "-0600") {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">7:00 PM a 9:35 PM</p>
  } else if (this.state.utc_offset === "-0400"){
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">9:00 PM a 11:35 PM</p>
  }  else {
      return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">8:00 PM a 10:35 PM</p>
  }
}

renderHorarioFinSemana(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 AM a 11:35 AM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">11:00 AM a 13:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">16:00 PM a 18:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">10:00 AM a 12:35 PM</p>
}

}

renderHorarioFinSemanaTarde(){
if (this.state.utc_offset === "-0500") {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
} else if (this.state.utc_offset === "-0600"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">02:00 PM a 04:35 PM</p>
} else if (this.state.utc_offset === "-0400"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">04:00 PM a 06:35 PM</p>
} else if (this.state.utc_offset === "+0100"){
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">09:00 PM a 11:35 PM</p>
} else {
  return  <p style={{fontSize: "18px", fontWeight: 500}} className="mb-1">03:00 PM a 05:35 PM</p>
}
}



    renderFechaSelect(item, key){
      return (
          <div key={key} className="row row--35 align-items-center rn-address" data-aos="fade-up"  style={{boxShadow: "#c6c9d8 8px 6px 15px 1px"}} >
          <div className="col-12 mb-3">

              <h2 style={{marginBottom: "0px", fontSize: window.type_device && "39px" }}>
                  {item.fecha_inicio}
              </h2>
              <div style={{width: window.type_device ? "200px": "450px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "20px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>

              <div className="row">
                  <div className="col-lg-2 col-md-3 col-12  mt-2">
                  <span style={{padding: "3px", "padding-top": "4px","padding-bottom": "4px", "background-color": "tomato", "border-radius": "7px", "padding-left": "7px", "padding-right": "7px", "color": "white", "font-size": "18px"}}>
                                  <FiVideo/> {!this.state.langUS ? "En vivo": "Live"}
                                  </span>
                  </div>
                  <div className="col-md-4 col-lg-3 col-12  mt-2">
                      <span style={{"padding-top": "4px","padding-bottom": "4px", "font-size": "18px", "color":"white", "background-color": "slateblue", "border-radius": "8px", "padding-left": "8px", "padding-right": "8px"}}>
                        Plazas limitadas
                      </span>
                  </div>
              </div>

          </div>

          <div className="col-md-3 col-12" style={{height: "110px", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2" ><b> {!this.state.langUS ? "Duración": "Duration"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {!this.state.langUS ? "5 meses": "5 months"}
              <span style={{display: "inline-block"}}></span>
              </p> </div>
              <span className="bg-white m-1" style={{padding: "5px 7px", border: "1px solid mediumpurple", display: "inline-block", borderRadius: "10px", fontSize: "16px"}}>
              <b>{item.fecha_start_end}</b>
              </span>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: window.type_device ? "25px": "none", borderRight: !window.type_device ? "1px solid #121212" : "none" }}>
              <div> <h5 className="mb-2"><b> {!this.state.langUS ? "Días": "Days"} </b></h5> </div>
              <div> <p style={{fontSize: "18px"}}> {item.dias}  </p> </div>
          </div>

          <div className="col-md-3 col-12" style={{height: "110px", marginTop: "none"}}>
          <div> <h5 className="mb-2">
              <b>
              Horario
               {item.turno === 'dia' &&  <> (Mañana) <BsSun style={{height: "35px", width: "35px", color: "goldenrod", marginLeft: "3px", fontSize: "26px"}}/>  </>  }
               {item.turno === 'tarde' && <> (Tarde) <BsCloudSunFill style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>  }
               {item.turno === 'noche' && <> (Noche) <MdModeNight style={{height: "35px", width: "35px", color: "darkblue", marginLeft: "3px", fontSize: "26px"}} /> </>   }
              </b>
              </h5> </div>
              <div className="row">
                  <div className="col-12">
                  {(item.turno == 'noche' || item.turno == 'night') && this.renderHorario() }
                  {item.turno == 'dia'  && this.renderHorarioFinSemana() }
                  {item.turno == 'tarde'  && this.renderHorarioFinSemanaTarde() }
                  </div>

              </div>
          </div>

          <div className="col-md-3 col-12" style={{marginTop: "30px"}}>
              <div>
              <a href={"#inscripcion"}>
              <button
              style={{lineHeight: "20px", fontSize: "18px", marginBottom: "13px", padding: "15px 30px" }}
              className=" btn-efect">
              ELEGIR FECHA
              </button>
              </a>
              </div>
          </div>

      </div>
      )
    }


    renderFechas() {
      return (
          <div>
              {!this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                   this.state.select_turno == item.turno && this.renderFechaSelect(item, key)
                  :
                  this.renderFechaSelect(item, key)

              ))}

              {this.state.select_mes && this.state.DataFechas.map((item, key) => (

                  this.state.select_turno ?
                  this.state.select_turno == item.turno && this.state.select_mes == item.mes && this.renderFechaSelect(item, key)
                 :
                  this.state.select_mes == item.mes && this.renderFechaSelect(item, key)


              ))}
        </div>

      )}






      
  // eventPing(name_event) {
  //   const config = {
  //     method: 'get',
  //     url: `https://api.dojofullstack.com/api/v1/crm/event/${name_event}`,
  //     headers: { 
  //       'Authorization': '0101010010001'
  //     }
  //   };
  //   axios(config)
  //     .then(response => {})
  //     .catch(error => {});
  // };



  render() {


    

    return (
      <React.Fragment style={{backgroundColor: "black"}}>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Bootcamp Full Stack Online en vivo" />
        {/* End Pagehelmet  */}

<div className="title-mural" style={{background: "rgb(27, 18, 18)"}}>

        <div  style={{paddingLeft: "1px",  paddingTop: "1px", textAlign: "center", maxWidth: "590px", background: "rgb(27, 18, 18)"}} >
    
    <div className="d-flex">
     <a href={'/unirme'}>
        <img style={{borderRadius: "50%"}} height="45px" class="logo-1" src="/assets/images/logo/dojopy_software.webp" alt="DojoFullStack"/>
    </a>
    <div className="logo_dojopy" style={{color: "white", marginTop: "5px", marginBottom: "5px", fontFamily: "'El Messiri', sans-serif", lineHeight: "19px", "place-self": "center", "margin-left": "5px"}}>

<a href={'/unirme'} style={{color: "white"}}>
<span style={{color: "white", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "29px": "30px" }}>
  DOJO
</span>
<span style={{color: "white", marginLeft: "1px", fontFamily: "'El Messiri', sans-serif", fontSize: window.type_device ? "13px": "14px", display: "block"}}>
  FULLSTACK
</span>
</a>
</div>
    </div>
    </div>
    </div>

        {/* Start Page Wrapper */}
       <div
          className="rn-service-details ptb--1 bg_color--1"
          style={{ textAlign: "center", background: "#1B1212" }}
        >
          <div className={window.type_device ? "w-100" : "container"}>
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="service-details-inner">
                  <div className="inner">



                    <div className="row justify-content-center">
                      <div className="col-12" data-aos="fade-right">
                        <h3 className="mb-1 title-gradient-2"
                        style={{
                          lineHeight: window.type_device ? "35px": "65px"
                        }}
                        >
                          <span
                           style={{fontFamily: "'Poppins',sans-serif",
                            fontStyle: "italic", fontSize: window.type_device ? "29px": "53px" }}
                           >
                          ¡De Cero a Desarrollador <br/> Web Full Stack!
                          </span>
                        </h3>
                      <h5 style={{fontStyle: "italic", marginBottom: "3px", fontSize: "17px", color: "whitesmoke"}}>Clic sobre el Vídeo 🔊 para activar el audio</h5> 
                      </div>

                    {!this.state.demoUrl  &&
                    <>
                           <Spinner
                          variant="dark"
                          as="span"
                          animation="grow"
                          role="status"
                          aria-hidden="true"
                          style={{fontSize: "20px"}}
                          />
                    </>
                    }


                    {this.state.demoUrl &&
                      <div
                        data-aos="fade-right"
                        className="col-12"
                        style={{
                          textAlign: "-webkit-center",
                          position: "relative",
                          maxHeight: window.type_device ? "450px" : "500px",
                        }}
                      >
                        <ReactPlayer
                          onClick={(e) => {
                            if (!this.state.videoInit){
                              document.querySelector("#video-play > video").currentTime = 0;
                              document.querySelector("#video-play > video").muted = false;
                              document.querySelector("#video-play > video").volume = 1;  
                              this.setState({ isPlaying: true, videoInit: true });
                            } else {
                              this.setState({ isPlaying: !this.state.isPlaying });
                            }
                          } }
                          muted={true}
                          ref={this.state.videoRef}
                          id="video-play"
                          style={{ backgroundColor: "#0d0d0d" }}
                          controls={false}
                          url={this.state.demoUrl}
                          playing={this.state.isPlaying}
                          width={"original"}
                          height={"100%"}
                          onProgress={(progress) => {
                              this.setState({durationPercen: (progress.playedSeconds/this.state.durationVideo)*100 || 0});
                              
                              if (progress.playedSeconds >= 30 && !this.state.sendEventContent && this.state.videoInit){
                                window.fbq('track', 'ViewContent');
                                this.setState({sendEventContent: true});
                              }
                                // if (progress.playedSeconds >= 65 && this.state.Email === '' && this.state.registerRequerid){
                                // this.setState({isOpenLead: true, isPlaying: false});
                                // }

                          }}
                          onDuration={(duration) => {
                            this.setState({durationVideo: duration});
                          }}
                        />
                   

                         <div style={{position: "relative", top: "-10px"}}>
                            <ProgressBar striped animated variant="dark" now={this.state.durationPercen} style={{maxWidth: "100%"}} />
                          </div>

                        <button
                           onClick={(e) => {
                            if (!this.state.videoInit){
                              document.querySelector("#video-play > video").currentTime = 0;
                              document.querySelector("#video-play > video").muted = false;
                              document.querySelector("#video-play > video").volume = 1;  
                              this.setState({ isPlaying: true, videoInit: true });
                            } else {
                              this.setState({ isPlaying: !this.state.isPlaying });
                            }
                          } }
                          className=" btn-solid m-3 play-buton-zoom buton-zoom"
                          style={{
                            margin: "5px",
                            fontWeight: "bold",
                            textTransform: "none",
                            display: this.state.isPlaying
                              ? "none"
                              : "inline-block",
                          }}
                     
                        >
                          <AiFillPlayCircle
                            style={{
                              color: "#111211",
                              marginRight: "6px",
                              marginBottom: "3px",
                              opacity: "0.8",
                              backgroundColor: "whitesmoke",
                              borderRadius: "50%",
                              fontSize: window.type_device ? "105px" : "180px",
                            }}
                          />
                        </button>

                        <button
                          className=" btn-solid m-2 pause-buton-zoom"
                          style={{
                            margin: "5px",
                            fontWeight: "bold",
                            textTransform: "none",
                            display: this.state.isPlaying
                              ? "inline-block"
                              : "none",
                          }}
                          onClick={(e) => this.setState({ isPlaying: false })}
                        >
                          <AiFillPauseCircle
                            style={{
                              marginRight: "5px",
                              marginBottom: "3px",
                              fontSize: "30px",
                              color: "white",
                            }}
                          />
                          <span
                            className="texto-btn"
                            style={{
                              fontSize: window.type_device ? "16px" : "20px",
                              color: "white",
                            }}
                          >
                          </span>
                        </button>

                    {this.state.isPlaying &&    <button
                          className=" btn-solid m-2 zoom-buton-zoom"
                          style={{ margin: "5px", fontWeight: "bold" }}
                          onClick={(e) => (
                            document
                              .querySelector("#video-play")
                              .requestFullscreen(),
                            this.setState({ isPlaying: true })
                          )}
                        >
                          <MdOutlineZoomOutMap
                            style={{ fontSize: "30px", color: "white" }}
                          />
                        </button>
  }

               
                
                      </div>
                    }










<div className="pt-1 pb-1 texto-especial" data-aos="fade-right">
             
<h2 className="dojo-h2 mt-0" style={{fontWeight: "bold", color: "white", padding: window.type_device ? "5px 20px": "20px 70px"  }}>
Descubre un plan estructurado y comprobado para comenzar tu carrera como Desarrollador Web Full Stack desde cero, ¡sin necesidad de experiencia previa!
 Te guiaremos en cada paso del camino para que adquieras las habilidades necesarias y te sumerjas en el emocionante mundo del desarrollo Web Full Stack.
  </h2>



  <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      


      <a href={this.GetLinkWS()}>
      <button
      onClick={() => {
        window.fbq('track', 'Contact', {
          content_type: 'product',
          content_ids: ['BOOTCAMP'], // Cambia con el ID del producto
          content_name: 'Bootcamp Web Full Stack' // Cambia con el nombre del producto
        });
        this.eventPing("Contact");
        // const link = this.GetLinkWS();
        // window.open(link, '_blank').focus();
      }}
      
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
🚀 Únete al Bootcamp: Más Información
</button>
      </a>
</div>


                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* <div className="row justify-content-center m-0 p-0">
      <div data-aos="fade-right" className="col-12 col-md-6 mt-1" style={{marginBottom: "15px"}}  id="">
     
     <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      


      <a href={this.GetLinkWS()}>
      <button
      onClick={() => {
        window.fbq('track', 'Contact', {
          content_type: 'product',
          content_ids: ['BOOTCAMP'], // Cambia con el ID del producto
          content_name: 'Bootcamp Web Full Stack' // Cambia con el nombre del producto
        });
        this.eventPing("Contact");
        // const link = this.GetLinkWS();
        // window.open(link, '_blank').focus();
      }}
      
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
🚀 Únete al Bootcamp: Más Información
</button>
      </a>
</div>
</div>
</div>  */}




<div className="about-wrapper" style={{paddingTop: window.type_device ? "1px": "29px", paddingBottom: "10px", background: "#1B1212" }}>
<div data-aos="fade-top"  className={window.type_device ? "container-fluid ": "container"}>

 <div className="d-flex mt-1 justify-content-center text-white" style={{flexDirection: window.type_device ? "column": "row", gap: window.type_device ? "1px": "15px" }}>

<div className="d-flex mb-3" style={{maxWidth: "540px", borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/3-face.jpg" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Mark Zuckerberg
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO Facebook</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>
        La gente siempre tiene miedo a lo desconocido. Pero si no te arriesgas a salir de tu zona de confort, nunca sabrás de lo que eres capaz.
        </p>
    </div>
  </div>

  <div className="d-flex mb-3" style={{maxWidth: "540px" , borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/1-face.webp" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Elon Musk
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>CEO Twitter</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>La vida es demasiado corta para ser pequeña. Tienes que hacer algo grande.</p>
    </div>
  </div>

  <div className="d-flex mb-1" style={{borderRadius: "20px", border: "1px solid mediumpurple", padding: "5px", alignItems: "center"}}>
    <img className="ml-1" style={{borderRadius: "30px", marginRight: "5px"}} height="89px" src="/assets/images/dojopy/2-face.jpeg" />
    <div className="ml-2 mt-2">
      <h5 style={{color: "white", fontStyle: "italic"}} className="my-0">Simón Borrero
        <span style={{marginLeft: "5px", fontSize: "13px", color: "mediumseagreen"}}>Founder Rappi</span>
      </h5>
      <p style={{fontSize: "14px", fontStyle: "italic"}}>
      La tecnología puede cambiar el mundo, pero el verdadero cambio lo generan las personas.
        </p>
    </div>
  </div> 



</div>
</div>
</div>






{/* 
<div className="about-wrapper" style={{paddingTop: window.type_device ? "5px": "50px", paddingBottom: "50px" }} id="webinar">

<div data-aos="fade-right"  className={window.type_device ? "container-fluid ": "container"}>
                     
        <div className="inner">
    <div className="contact-form--1">
            <div className="row" data-aos="fade-right" >
                <div className="col-12"
                style={{paddingLeft: window.type_device ? "5px": "50px", paddingRight: window.type_device ? "5px": "50px"}}
                >
                <div className="mx-3">
                  <MemoizedTypingAnimation/>
                <div className=" mx-2" style={{width: window.type_device ? "250px": "395px", height: "6px", backgroundColor: "#ffcb48", marginBottom: "17px", transform: "rotate(178deg)", marginTop: "-2px"}}></div>
                </div>

                    <span
                     style={{display: "inline-block", padding: "3px", marginLeft: "15px", "background-color": "tomato", "border-radius": "17px", "padding-left": "15px", "padding-right": "15px", "color": "white", "font-size": "18px"}}>
                    <span class="pulsar"></span> Online en vivo
                    </span>

                    <div className="mx-2 mt-1" data-aos="fade-right">
                        <p className="p-2">
                       <b>Aquí hay algunas transformaciones que experimentarás:</b> 
                        </p>
                    </div>


                 <div className="mx-2 mt-1" data-aos="fade-right" style={{
                  background: "linear-gradient(to right, #8e2de2, #4a00e0)",
                  color: "white",
                  borderRadius: "19px",
                  padding: "13px"
                 }}>
                  
                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Crea Aplicaciones Web Completas e Impresionantes.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Contribuye en una Industria Tecnológica Valorada en Billones.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Descubre tu guía definitiva para dominar las tecnologías más solicitadas por las empresas: ¡HTML, JavaScript, React, Python y muchas más!
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Oportunidades Laborales Infinitas.
                    </p>

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Confianza para Innovar.
                    </p>
             

                    <p style={{marginBottom: window.type_device ? "8px": "15px", fontSize: "18px"}}>
                    <span style={{"color":"slateblue", "fontSize": "28px"}}> <FiCheckCircle style={{fontSize: "25px", marginRight: "7px", color: "white"}} /> </span>
                    Crecimiento Profesional y Personal.
                    </p>
                 
                </div>


                <div className="row justify-content-center mx-1">
      <div data-aos="fade-right" className="col-12 col-md-6 mt-1" style={{marginBottom: "25px"}}  id="registro">
     
     <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      
      <a href="#registro">
      <button
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
 ¡Obtén Más Información!
</button>
      </a>

</div>
</div>

</div>

                </div>


                </div>
        </div>
        </div>
        
        </div>
        </div>

 */}





{/* 
    <div data-aos="fade-right" className="about-area ptb--40 bg_color--5" id="benefits" style={{marginTop: "-100px"}}>
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">

                                <div className="col-12">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">
                                              ¿Para quién es este Bootcamp?
                                            </h2>

                                        </div>

                                        <TouchCarousel
                                        component={Container2}
                                        cardSize={cardSize2}
                                        cardCount={data2.length}
                                        cardPadCount={cardPadCount}
                                        loop={true}
                                        autoplay={2e3}
                                        renderCard={this.renderCard2}
                                        />


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
 */}




    


  {/* Start About Area */}
  {false &&
    <>
      <div className="container px-1" data-aos="fade-right" >
            <h2 className="title mb-2 mt-1 mx-2" style={{fontSize: window.type_device ? "36px": "37px"}} >
            Testimonios de Nuestros Alumnos
                </h2>
                <span
                     style={{fontStyle: "italic", display: "block", padding: "3px", marginLeft: "7px", "border-radius": "7px", "color": "black", "font-size": window.type_device ? "19px": "30px" }}>
                          Más de 300 Alumnos Satisfechos
                    </span>
        </div>
  
      <div className="about-area bg_color--5">
                    <div className="about-wrapper">
                        <div className="container px-0">
                            <div className="row row--35 align-items-center">
                            
                            <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner pt-2">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Judith</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/alN6nU5a2Vo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>  
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Bladimir</h3>
                                        <h3 className="title" style={{color: "orange"}}>⭐ Consiguió Empleo</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/dDPWdY-S8-0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Tatiana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/NhOFeo9_rA4?si=_LWhcwSgAOlmF0Od" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Harold Ivan</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/CJWjmlO3_7k?si=0VWDgUmLhp4pyNRg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                              

                                {!this.state.showMore &&
                                            <div className="col-12 text-center" style={{marginBottom: "10px"}}>
                                                <button
                                                onClick={() => {
                                                  this.setState({showMore: true});
                                                }}
                                                style={{ marginTop: "5px", marginBottom: "5px", fontSize: "18px",
                                                "border-radius": "20px",
                                                "border": "2px solid #6a5acd",
                                                "padding": "9px 20px"

                                            }}
                                                >
                                                {!this.state.langUS ? "Mostrar más": "Show more"} <br/>
                                                <AiOutlineDown style={{padding: "5px", fontSize: "30px"}}/>
                                                </button>
                                            </div>}




<div className="row justify-content-center m-0 p-0">
      <div data-aos="fade-right" className="col-12 mt-1" style={{marginBottom: "15px"}} >
     
     <div className="mb-2" style={{paddingTop: "5px", paddingBottom: "5px" }}>
      
      <a href="#registro" >
      <button
  className="btn-efect-live"
   style={{borderRadius: "15px", padding: "15px", color: "white", width: "100%", fontSize: "25px", display: "block", margin: "auto", textTransform: "capitalize"}}>
🌟 ¡Explora Todos los Detalles!
</button>
      </a>

</div>
</div>

</div>


{this.state.showMore &&
                                  <>

                                  <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Stephany</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/330Wxw_z4_0?si=Ms_tnorjQRspKGrw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Ricardo Osorio</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/ZaR2-71Kg9Q?si=pz_7oW3sL6gOP2oG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title">Testimonio Diana</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/gpLkbRA4ZrE?si=UV0v9lnJQn2IdwAA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-12 mt-3 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                            <h3 className="title">Testimonio Paul</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/81xtrnKV9Ss" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Oscar</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/qtu4qNnBZbI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-12 mt-4 mb-2">
                                    <div className="about-inner inner">
                                        <div className="section-title" style={{marginTop: "10px"}}>
                                        <h3 className="title pt-4">Testimonio Andrés</h3>
                                            <div>
                                            <iframe loading="lazy"  width={window.type_device ? "350": "560"} height="315" src="https://www.youtube.com/embed/Xxif1Dc_ifQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                  </>

}
                              




                            </div>
                        </div>
                    </div>
                </div>
      </>
  }





<Modal show={this.state.isModalWS} size={window.type_device ? "md": "lg"} onHide={() => this.setState({isModalWS: true})} style={{zIndex: "9999999999"}} >

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                          <h3 className="mt-3 p-2 text-dark">🌟 ¡Hola! {this.state.Name}</h3>
                            <p className="p-2 text-dark">
                            📲 Envíanos un mensaje por WhatsApp y
                            <b> recibirás todos los detalles de inmediato. </b>
                              ¡Estamos aquí para ayudarte!
                            </p>

                            <div className="w-100 m-1">
                              <button onClick={() => {
                                 const link = this.GetLinkWS();
                                 window.open(link, '_blank').focus();
                              }} className="btn-grupo-ws">
                                <FaWhatsapp style={{marginRight: "5px", width: "25px", height: "25px", position: "relative", bottom: "3px"}}/>
                                ¡Sí, Quiero Más Información! 
                                </button>

                                <p>
                                  
                                </p>
                            </div>

                           
                    </div>
                    </div>
                    </Modal.Body>
                </Modal>




        <Modal show={this.state.isOpen2} size="md" onHide={this.openModal2} style={{zIndex: "9999999999"}} >
                <span style={{textAlign: "left", "color":"slateblue"}}> <IoCloseCircleOutline onClick={() => this.openModal2()} style={{ cursor: "pointer", fontSize: "35px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="about-inner inner">
                        <div className="section-title">
                            <p>
No te preocupes. Nuestros programas son aptos para principiantes y no requieren ningún conocimiento previo ni hay limite de edad.
Además, no estarás solo. Nuestro equipo estará a tu lado para guiarte en el proceso, ayudarte a establecer objetivos y a tener éxito.
                            </p>
                            <p>
                                <b>*Instalar la herramienta de transmisión en vivo:</b>
                                <img alt="canal zoom" height="95px" src="/assets/images/dojopy/zoom_.webp"></img>
                            </p>
                    </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer style={{"justify-content": "center"}}>
                    <button className="rn-btn" onClick={this.openModal2}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                    </Modal.Footer>
                </Modal>



        <Modal show={this.state.isOpenResume} size="md" onHide={() => this.setState({isOpenResume: false})} style={{zIndex: "9999999999"}} >
                    <span style={{textAlign: "left", "color":"slateblue"}}>
                         <IoCloseCircleOutline onClick={() => this.setState({isOpenResume: false}) }
                         style={{ cursor: "pointer", fontSize: "45px", marginLeft: "12px", marginTop: "12px", color: "slateblue"}} /> </span>

                    <Modal.Body>
                    <div className="">
                        <div className="section-title">
                            <h3 className="title"></h3>

                            <div className="pricing-body mb-3">

                                      <ul className="list-style--1" style={{marginTop: "5px", color: 'black'}}>

                                        <div className="row">
                                            <div className="col-1">
                                                <span style={{"color":"slateblue", "fontSize": "22px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "4px", color: "slateblue"}} /> </span>
                                            </div>
                                            <div className="col-10 pl-2">
                                            <p style={{fontSize: "16px", "margin-bottom": "5px", fontWeight: "bold"}}>
                                                <b style={{color: "black"}}> Te ayudamos a despegar y potenciar tus habilidades, esto puede ahorrarle años valiosos de su tiempo.</b> <br/>
                                            </p>
                                            </div>
                                            </div>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende 100% online en vivo. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          {!this.state.langUS ? "5 meses": "5 months"} (Part-time)<br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          5 horas en Vivo y 2 horas de actividades asíncronas, cada semana. <br/>
                                          </p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                           Seguimiento y mentorías. <br/>
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Prácticas y Código colaborativo en tiempo real en cada sesión.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Aprende con proyectos Full Stack reales <br/> (Tienda Online, Web de pedidos)
                                          </p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Las sesiones en vivo quedan grabadas, acceso a la plataforma sin límite de tiempo, para seguir practicando y aprendiendo. <br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Certificación<br/></p>

                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Una comunidad de apoyo profesional de por vida.<br/></p>


                                          <p style={{fontSize: "16px", "margin-bottom": "5px"}}>
                                          <span style={{marginRight: "2px", "color":"slateblue", "fontSize": "20px"}}> <FiCheckCircle style={{fontSize: "20px", marginRight: "7px", color: "slateblue"}} /> </span>
                                          Portafolio de proyectos y carta de presentación optimizada 👌.
                                          <br/></p>


                                      </ul>
                                      </div>
                                      <div className="mb-1 text-center">
                                        <button style={{fontSize: "18px"}} className="rn-button-style--2 btn-solid" onClick={() => this.setState({isOpenResume: false})}>{!this.state.langUS ? "Cerrar": "Close"}</button>
                                      </div>
                    </div>


                    </div>


                    </Modal.Body>
               
                </Modal>





  <ToastContainer
                position="bottom-center"
                autoClose={5000}
                zIndex={10000}
                />


        <FooterTwoFunnel  />
        
      </React.Fragment>
    );
  }

  async componentDidMount() {

    // document.getElementById("whatsapp").style.display = "block";


//   this.sleep(10000).then(r => {
//     this.setState({ showButton: true });
// })


    const lang = window.location.pathname;

    const pathdata = window.location.href;

    const keyword = "webinar";
    let registerRequerid = false;
    const currentUrl = window.location.href;
    if (currentUrl.includes(keyword)) {
      registerRequerid = true;
    }

    const demoId = "evento";
    window.urlWasi = this.GetLinkWS();

    
    try {
      const responseIP = await axios.get("https://ipapi.co/json/");
      let dataPriceIP = responseIP.data;
      if (!dataPriceIP.country_code) throw "api error";

      this.setState({countryCode: dataPriceIP.country_code});
      this.DynamicCountry(dataPriceIP.country_code);

  } catch {
      try {
          const response = await axios.get('https://bdzpehnjfmdq56bcrp6gb4mcru0nxqci.lambda-url.us-east-1.on.aws/');
          let data = response.data;
          this.setState({ countryCode: data.country_code });
          this.DynamicCountry(data.country_code);
          
      } catch (error) {
          console.log("Error API", error);
      }
  }





  }
}

export default VslDefault;
